import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { Switch, TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Edit, PersonAdd } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import ListErrors from '../ListErrors'
import agent from '../../agent'

import {
  ADD_STUDENT,
  STUDENT_DIALOG_UNLOADED,
  UPDATE_STUDENT,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.common.settings,
  ...state.courses,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (user, courseId) =>
    dispatch({
      type: ADD_STUDENT,
      payload: agent.Students.add(user, courseId),
      courseId,
      snackbar: { message: 'Student added', variant: 'success' },
    }),
  onExit: () => dispatch({ type: STUDENT_DIALOG_UNLOADED }),
  onUpdate: (user, courseId) =>
    dispatch({
      type: UPDATE_STUDENT,
      payload: agent.Students.update(user),
      courseId,
      snackbar: { message: 'Student updated', variant: 'success' },
    }),
})

const StudentDialog = ({
  course,
  defaultStudentPassword,
  errors,
  inProgress,
  onAdd,
  onExit,
  onUpdate,
  prime,
  songs,
  student,
}) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitAdd = (values) => {
    const user = { ...values }
    const courseId = course._id
    user.role = 'student'
    onAdd(user, courseId)
  }

  const handleSubmitUpdate = (values) => {
    const user = { ...student, ...values }
    const courseId = course._id
    onUpdate(user, courseId)
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  return (
    <>
      {student ? (
        <ActionButton
          action='Edit student'
          onClick={handleClickOpen}
          size='small'
        >
          <Edit fontSize='inherit' />
        </ActionButton>
      ) : (
        <ActionButton action='Add student' onClick={handleClickOpen}>
          <PersonAdd />
        </ActionButton>
      )}

      <Dialog
        fullScreen={fullScreen}
        maxWidth='md'
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onExit={onExit}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
      >
        <Formik
          initialValues={{
            firstName: student?.firstName || '',
            lastName: student?.lastName || '',
            username: student?.username || '',
            password: !student ? defaultStudentPassword : '',
            dropped: student?.dropped || false,
            hasPrime: student?.hasPrime || false,
            anonymousSongs: student?.anonymousSongs || false,
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            username: Yup.number()
              .typeError('Numbers only')
              .required('Required'),
            password: Yup.string().required(!student ? 'Required' : ''),
            dropped: Yup.boolean(),
            hasPrime: Yup.boolean(),
            anonymousSongs: Yup.boolean(),
          })}
          onSubmit={async (values) => {
            student ? handleSubmitUpdate(values) : handleSubmitAdd(values)
          }}
        >
          {(formik) => (
            <Form>
              <DialogTitle>
                {student ? 'Edit Student' : 'Add Student'}
              </DialogTitle>
              <DialogContent>
                <ListErrors errors={errors} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      autoFocus={student ? false : true}
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='First Name'
                      name='firstName'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Last Name'
                      name='lastName'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={!!student || inProgress}
                      label='Username'
                      name='username'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      color='primary'
                      component={TextField}
                      disabled={inProgress}
                      label='Password'
                      name='password'
                      type='text'
                      fullWidth
                    />
                  </Grid>
                  {student && (
                    <Grid item xs={12} md={6}>
                      <label>
                        <Field
                          component={Switch}
                          name='dropped'
                          type='checkbox'
                        />
                        Student is{' '}
                        {formik.values.dropped ? 'dropped' : 'active'}
                      </label>
                    </Grid>
                  )}
                  {student && prime.enabled && (
                    <Grid item xs={12} md={6}>
                      <label>
                        <Field
                          component={Switch}
                          name='hasPrime'
                          type='checkbox'
                        />
                        Prime is{' '}
                        {formik.values.hasPrime ? 'enabled' : 'disabled'}
                      </label>
                    </Grid>
                  )}
                  {student && songs.allowAnonymous && (
                    <Grid item xs={12} md={6}>
                      <label>
                        <Field
                          component={Switch}
                          name='anonymousSongs'
                          type='checkbox'
                        />
                        Anonymous songs{' '}
                        {formik.values.anonymousSongs ? 'enabled' : 'disabled'}
                      </label>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  disabled={inProgress || !(formik.isValid && formik.dirty)}
                  type='submit'
                  variant='contained'
                >
                  {student
                    ? inProgress
                      ? 'Updating...'
                      : 'Update'
                    : inProgress
                    ? 'Adding...'
                    : 'Add'}
                </Button>
                <Button
                  disabled={inProgress}
                  onClick={handleClose}
                  variant='contained'
                >
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentDialog)
