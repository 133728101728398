import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { Select, Switch, TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Lock, SaveAlt } from '@material-ui/icons'

import ControlledSwitch from './ControlledSwitch'
import JukeboxRequestDialog from './Jukebox/JukeboxRequestDialog'
import ListErrors from './ListErrors'
import PageHeader from './PageHeader'
import PlaylistManagerDialog from './Jukebox/PlaylistManagerDialog'
import PrimeBadge from './Prime/PrimeBadge'
import SpotifyLinker from './Teacher/SpotifyLinker'
import agent from '../agent'

import {
  SETTINGS_PAGE_UNLOADED,
  SETTINGS_SAVED,
  WHATS_NEW_DIALOG_LOADED,
} from '../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  gridCenter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  info: {
    backgroundColor: theme.palette.background.default,
    borderLeft: `4px solid ${theme.palette.info.main}`,
    fontStyle: 'italic',
    padding: theme.spacing(1),
  },
  lock: {
    alignItems: 'center',
    color: theme.palette.secondary.main,
    display: 'inline-flex',
    fontStyle: 'italic',
    marginLeft: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}))

const mapStateToProps = (state) => ({
  ...state.settings,
  currentUser: state.common.currentUser,
  settings: state.common.settings,
})

const mapDispatchToProps = (dispatch) => ({
  onSubmitForm: (settings) =>
    dispatch({
      type: SETTINGS_SAVED,
      payload: Promise.all([
        agent.Auth.save(settings),
        agent.Settings.update(settings),
      ]),
      snackbar: { message: 'Settings saved', variant: 'success' },
    }),
  onUnload: () => dispatch({ type: SETTINGS_PAGE_UNLOADED }),
  onWhatsNewClick: () =>
    dispatch({
      type: WHATS_NEW_DIALOG_LOADED,
      payload: agent.Auth.save({ viewedChanges: false }),
    }),
})

const Settings = ({
  currentUser,
  errors,
  inProgress,
  onSubmitForm,
  onUnload,
  onWhatsNewClick,
  settings,
}) => {
  const classes = useStyles()

  useEffect(() => {
    return () => {
      onUnload()
    }
  }, [])

  const primeMessage =
    'Chem Cash Prime allows students to pay a one-time fee for access to special ' +
    'features and discounts...just another way for students to spend their points!'

  const SwitchLabel = ({ status, value }) =>
    `${value} ${status ? 'enabled' : 'disabled'}`

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        allowPurchasesWithInsufficientFunds:
          settings?.allowPurchasesWithInsufficientFunds || false,
        anonymousSongs: currentUser.anonymousSongs || false,
        darkMode: currentUser.darkMode || false,
        defaultStudentPassword: settings?.defaultStudentPassword || '',
        email: settings?.notifications?.email || '',
        enableBetaFeatures: settings?.enableBetaFeatures || false,
        finesEnabled: settings?.fines?.enabled || false,
        firstName: currentUser.firstName,
        hallpassAllowPurchaseWithInsufficientFunds:
          settings?.hallpass?.allowPurchaseWithInsufficientFunds || false,
        hallpassAllowStudentCreation:
          settings?.hallpass?.allowStudentCreation || false,
        hallpassDefaultDuration: settings?.hallpass?.defaultDuration || 5,
        hallpassEnabled: settings?.hallpass?.enabled || false,
        hallpassLimit: settings?.hallpass?.limit || 0,
        hallpassPurchaseAmount: settings?.hallpass?.purchaseAmount || 0,
        jukeboxEnabled: settings?.jukebox?.enabled || false,
        jukeboxPurchaseAmount: settings?.jukebox?.purchaseAmount || 0,
        jukeboxPurchaseLimit: settings?.jukebox?.purchaseLimit || 0,
        jukeboxRequested: settings?.jukebox?.requested || false,
        jukeboxSkipSongsEnabled: settings?.jukebox?.skipSongs?.enabled || false,
        jukeboxSkipSongsPurchaseAmount:
          settings?.jukebox?.skipSongs?.purchaseAmount || 0,
        lastName: currentUser.lastName,
        notificationsEnabledForSongs:
          settings?.notifications?.enabled?.forSongs || false,
        notificationsEnabledForStore:
          settings?.notifications?.enabled?.forStore || false,
        password: '',
        preventDuplicateSong: settings?.songs?.preventDuplicateSong || false,
        primeAnonymousSongsEnabled:
          settings?.prime?.anonymousSongsEnabled || false,
        primeDarkModeEnabled: settings?.prime?.darkModeEnabled || false,
        primeEnabled: settings?.prime?.enabled || false,
        primeHallpassDiscount: settings?.prime?.hallpassDiscount || 0,
        primeJukeboxEnabled: settings?.prime?.jukeboxEnabled || false,
        primePurchaseAmount: settings?.prime?.purchaseAmount || 0,
        primeSameDayDeliveryEnabled:
          settings?.prime?.sameDayDeliveryEnabled || false,
        primeSongRemovalEnabled: settings?.prime?.songRemovalEnabled || false,
        primeSongRequestPurchaseDiscount:
          settings?.prime?.songRequestPurchaseDiscount || 0,
        primeStorePurchaseDiscount: settings?.prime?.storePurchaseDiscount || 0,
        productsEnabled: settings?.productsEnabled || false,
        roomNumber: settings?.roomNumber || 0,
        schoolDistrict: settings.schoolDistrict || 'none',
        score100: settings?.scoreConverter?.[100] || 1200,
        score90: settings?.scoreConverter?.[90] || 1000,
        score80: settings?.scoreConverter?.[80] || 800,
        score70: settings?.scoreConverter?.[70] || 600,
        score60: settings?.scoreConverter?.[60] || 0,
        score50: settings?.scoreConverter?.[50] || 0,
        songRemovalPurchaseAmount: settings?.songRemovalPurchaseAmount || 0,
        songRemovalRequestEnabled: settings?.songRemovalRequestEnabled || false,
        songRemovalRefundAmount: settings?.songRemovalRefundAmount || 0,
        songRequestAnonymousEnabled: settings?.songs?.allowAnonymous || false,
        songRequestEnabled: settings?.songRequestEnabled || false,
        songRequestPurchaseAmount: settings?.songRequestPurchaseAmount || 0,
        storeEnabled: settings?.storeEnabled || false,
        taxRate: settings?.taxRate || 0,
        title: currentUser?.title || '',
      }}
      validationSchema={Yup.object({
        allowPurchasesWithInsufficientFunds: Yup.boolean(),
        anonymousSongs: Yup.boolean(),
        darkMode: Yup.boolean(),
        defaultStudentPassword: Yup.string(),
        email: Yup.string().email(),
        enableBetaFeatures: Yup.boolean(),
        finesEnabled: Yup.boolean(),
        firstName: Yup.string(),
        hallpassAllowPurchaseWithInsufficientFunds: Yup.boolean(),
        hallpassAllowStudentCreation: Yup.boolean(),
        hallpassDefaultDuration: Yup.number().typeError('Numbers only'),
        hallpassEnabled: Yup.boolean(),
        hallpassLimit: Yup.number().typeError('Numbers only'),
        hallpassPurchaseAmount: Yup.number().typeError('Numbers only'),
        jukeboxEnabled: Yup.boolean(),
        jukeboxPurchaseAmount: Yup.number().typeError('Numbers only'),
        jukeboxPurchaseLimit: Yup.number().typeError('Numbers only'),
        jukeboxRequested: Yup.boolean(),
        jukeboxSkipSongsEnabled: Yup.boolean(),
        jukeboxSkipSongsPurchaseAmount: Yup.number().typeError('Numbers only'),
        lastName: Yup.string(),
        notificationsForSongs: Yup.boolean(),
        notificationsForStore: Yup.boolean(),
        password: Yup.string(),
        preventDuplicateSong: Yup.boolean(),
        primeAnonymousSongsEnabled: Yup.boolean(),
        primeDarkModeEnabled: Yup.boolean(),
        primeEnabled: Yup.boolean(),
        primeHallpassDiscount: Yup.number().typeError('Numbers only'),
        primeJukeboxEnabled: Yup.boolean(),
        primePurchaseAmount: Yup.number().typeError('Numbers only'),
        primeSameDayDeliveryEnabled: Yup.boolean(),
        primeSongRemovalEnabled: Yup.boolean(),
        primeSongRequestPurchaseDiscount:
          Yup.number().typeError('Numbers only'),
        primeStorePurchaseDiscount: Yup.number().typeError('Numbers only'),
        productsEnabled: Yup.boolean(),
        roomNumber: Yup.number(),
        schoolDistrict: Yup.string(),
        score100: Yup.number().typeError('Numbers only'),
        score90: Yup.number().typeError('Numbers only'),
        score80: Yup.number().typeError('Numbers only'),
        score70: Yup.number().typeError('Numbers only'),
        score60: Yup.number().typeError('Numbers only'),
        score50: Yup.number().typeError('Numbers only'),
        songRemovalPurchaseAmount: Yup.number().typeError('Numbers only'),
        songRemovalRequestEnabled: Yup.boolean(),
        songRemovalRefundAmount: Yup.number().typeError('Numbers only'),
        songRequestAnonymousEnabled: Yup.boolean(),
        songRequestEnabled: Yup.boolean(),
        songRequestPurchaseAmount: Yup.number().typeError('Numbers only'),
        storeEnabled: Yup.boolean(),
        taxRate: Yup.number().typeError('Numbers only'),
        title: Yup.string(),
      })}
      onSubmit={async (values, form) => {
        const userSettings = { ...values }

        if (!userSettings.password) delete userSettings.password

        if (currentUser.role !== 'student') {
          userSettings.fines = {
            enabled: values.finesEnabled,
          }

          userSettings.hallpass = {
            allowPurchaseWithInsufficientFunds:
              values.hallpassAllowPurchaseWithInsufficientFunds,
            allowStudentCreation: values.hallpassAllowStudentCreation,
            defaultDuration: values.hallpassDefaultDuration,
            enabled: values.hallpassEnabled,
            limit: values.hallpassLimit,
            purchaseAmount: values.hallpassPurchaseAmount,
          }

          userSettings.jukebox = {
            enabled: values.jukeboxEnabled,
            purchaseAmount: values.jukeboxPurchaseAmount,
            purchaseLimit: values.jukeboxPurchaseLimit,
            requested: values.jukeboxRequested,
            skipSongs: {
              enabled: values.jukeboxSkipSongsEnabled,
              purchaseAmount: values.jukeboxSkipSongsPurchaseAmount,
            },
          }

          userSettings.notifications = {
            email: values.email,
            enabled: {
              forSongs: values.notificationsEnabledForSongs,
              forStore: values.notificationsEnabledForStore,
            },
          }

          userSettings.prime = {
            anonymousSongsEnabled: values.primeAnonymousSongsEnabled,
            darkModeEnabled: values.primeDarkModeEnabled,
            enabled: values.primeEnabled,
            hallpassDiscount: values.primeHallpassDiscount,
            jukeboxEnabled: values.primeJukeboxEnabled,
            purchaseAmount: values.primePurchaseAmount,
            sameDayDeliveryEnabled: values.primeSameDayDeliveryEnabled,
            songRemovalEnabled: values.primeSongRemovalEnabled,
            songRequestPurchaseDiscount:
              values.primeSongRequestPurchaseDiscount,
            storePurchaseDiscount: values.primeStorePurchaseDiscount,
          }

          userSettings.scoreConverter = {
            100: values.score100,
            90: values.score90,
            80: values.score80,
            70: values.score70,
            60: values.score60,
            50: values.score50,
            40: 0,
            30: 0,
            20: 0,
            10: 0,
            0: 0,
          }

          userSettings.songs = {
            allowAnonymous: values.songRequestAnonymousEnabled,
            preventDuplicateSong: values.preventDuplicateSong,
          }
        }

        onSubmitForm(userSettings)

        form.resetForm({
          values: { ...userSettings, password: '' },
        })
      }}
    >
      {(formik) => (
        <Form>
          <PageHeader title='Manage Account'>
            <Button onClick={onWhatsNewClick} size='small'>
              See What's New
            </Button>
            <Fab
              color='primary'
              disabled={inProgress}
              type='sumbit'
              variant='extended'
            >
              <SaveAlt className={classes.icon} />
              {inProgress ? 'Saving...' : 'Save settings'}
            </Fab>
          </PageHeader>
          <ListErrors errors={errors} />
          {currentUser.role !== 'student' && (
            <Paper className={classes.paper}>
              <Grid container spacing={2} style={{ alignItems: 'center' }}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Account Details
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    label='First Name'
                    name='firstName'
                    type='text'
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    label='Last Name'
                    name='lastName'
                    type='text'
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
                {currentUser.role === 'teacher' && (
                  <>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel>Title</InputLabel>
                        <Field component={Select} label='Title' name='title'>
                          <MenuItem value='Dr.'>Dr.</MenuItem>
                          <MenuItem value='Miss.'>Miss.</MenuItem>
                          <MenuItem value='Mr.'>Mr.</MenuItem>
                          <MenuItem value='Mrs.'>Mrs.</MenuItem>
                          <MenuItem value='Ms.'>Ms.</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel>School District</InputLabel>
                        <Field
                          component={Select}
                          label='School District'
                          name='schoolDistrict'
                        >
                          <MenuItem value='none'></MenuItem>
                          <MenuItem value='ccsd'>CCSD</MenuItem>
                          <MenuItem value='evergreen'>Evergreen</MenuItem>
                          <MenuItem value='lehi'>Lehi</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label>
                        <Field
                          component={Switch}
                          name='enableBetaFeatures'
                          type='checkbox'
                        />
                        <SwitchLabel
                          status={formik.values.enableBetaFeatures}
                          value='Beta features'
                        />
                      </label>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          )}
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h5' gutterBottom>
                  Account Security
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  label='New Password'
                  name='password'
                  type='password'
                  variant='outlined'
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
          {currentUser.role === 'student' && (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Appearance
                  </Typography>
                  <Grid item xs={12}>
                    <label style={{ alignItems: 'center', display: 'flex' }}>
                      <Field
                        component={Switch}
                        disabled={
                          settings.prime.enabled &&
                          settings.prime.darkModeEnabled &&
                          !currentUser.hasPrime
                        }
                        name='darkMode'
                        type='checkbox'
                      />
                      <SwitchLabel
                        status={formik.values.darkMode}
                        value='Dark mode'
                      />
                      {settings.prime.enabled &&
                        settings.prime.darkModeEnabled &&
                        !currentUser.hasPrime && (
                          <span className={classes.lock}>
                            <Lock /> (available with Chem Cash Prime)
                          </span>
                        )}
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          )}
          {currentUser.role !== 'student' && (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Appearance
                  </Typography>
                  <Grid item xs={12}>
                    <label>
                      <Field
                        component={Switch}
                        name='darkMode'
                        type='checkbox'
                      />
                      <SwitchLabel
                        status={formik.values.darkMode}
                        value='Dark mode'
                      />
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          )}
          {currentUser.role === 'teacher' && (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Fines
                  </Typography>
                  <Grid item xs={12}>
                    <label>
                      <Field
                        component={Switch}
                        name='finesEnabled'
                        type='checkbox'
                      />
                      <SwitchLabel
                        status={formik.values.finesEnabled}
                        value='Fines'
                      />
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          )}
          {currentUser.role === 'teacher' && (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Hallpasses
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <label>
                    <Field
                      component={Switch}
                      name='hallpassEnabled'
                      type='checkbox'
                    />
                    <SwitchLabel
                      status={formik.values.hallpassEnabled}
                      value='Hallpasses'
                    />
                  </label>
                </Grid>
                {formik.values.hallpassEnabled && (
                  <>
                    <Grid item xs={12} md={3}>
                      <Field
                        component={TextField}
                        label='Hallpass Purchase Amount'
                        name='hallpassPurchaseAmount'
                        type='number'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        component={TextField}
                        label='Default Hallpass Duration (min)'
                        name='hallpassDefaultDuration'
                        type='number'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Field
                        component={TextField}
                        label='Hallpass Limit'
                        name='hallpassLimit'
                        type='number'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label>
                        <Field
                          component={Switch}
                          name='hallpassAllowStudentCreation'
                          type='checkbox'
                        />
                        <SwitchLabel
                          status={formik.values.hallpassAllowStudentCreation}
                          value='Student-generated hallpasses'
                        />
                      </label>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label>
                        <Field
                          component={Switch}
                          name='hallpassAllowPurchaseWithInsufficientFunds'
                          type='checkbox'
                        />
                        <SwitchLabel
                          status={
                            formik.values
                              .hallpassAllowPurchaseWithInsufficientFunds
                          }
                          value='Purchase with insufficient funds'
                        />
                      </label>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Field
                        component={TextField}
                        label='Classroom Number'
                        name='roomNumber'
                        type='number'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          )}
          {currentUser.role === 'teacher' &&
            formik.values.enableBetaFeatures && (
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h5' gutterBottom>
                      Jukebox
                      <sup>
                        <em>
                          <small>beta</small>
                        </em>
                      </sup>
                    </Typography>
                  </Grid>
                  {currentUser.permissions.jukebox ? (
                    <>
                      <Grid item xs={12} md={4}>
                        <label>
                          <Field
                            component={Switch}
                            name='jukeboxEnabled'
                            type='checkbox'
                          />
                          <SwitchLabel
                            status={formik.values.jukeboxEnabled}
                            value='Jukebox'
                          />
                        </label>
                      </Grid>
                      {formik.values.jukeboxEnabled && (
                        <>
                          <Grid item xs={12} md={4}>
                            <SpotifyLinker />
                          </Grid>
                          {settings.spotifyTokens && (
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{ textAlign: 'center' }}
                            >
                              <PlaylistManagerDialog />
                            </Grid>
                          )}
                          <Grid item xs={12} md={4}>
                            <Field
                              component={TextField}
                              label='Jukebox Purchase Amount'
                              name='jukeboxPurchaseAmount'
                              type='number'
                              variant='outlined'
                              fullWidth
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.gridCenter}
                          >
                            <label>
                              <Field
                                component={Switch}
                                name='jukeboxSkipSongsEnabled'
                                type='checkbox'
                              />
                              <SwitchLabel
                                status={formik.values.jukeboxSkipSongsEnabled}
                                value='Skip songs'
                              />
                            </label>
                          </Grid>
                          {formik.values.jukeboxSkipSongsEnabled && (
                            <Grid item xs={12} md={4}>
                              <Field
                                component={TextField}
                                label='Skip Songs Purchase Amount'
                                name='jukeboxSkipSongsPurchaseAmount'
                                type='number'
                                variant='outlined'
                                fullWidth
                              />
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Typography variant='body1' gutterBottom>
                          Jukebox connects your Spotify account to Chem Cash to
                          allow students to see your queue and to add songs to
                          the queue from their class playlist of approved songs.
                          Jukebox is currently available to teachers by request
                          only.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.gridCenter}>
                        <JukeboxRequestDialog
                          requested={formik.values.jukeboxRequested}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Paper>
            )}
          {currentUser.role === 'teacher' && (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Notifications
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    label='Email Address'
                    name='email'
                    type='email'
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={4}>
                    <label>
                      <Field
                        component={Switch}
                        name='notificationsEnabledForSongs'
                        type='checkbox'
                      />
                      <SwitchLabel
                        status={formik.values.notificationsEnabledForSongs}
                        value='Song purchase notifications'
                      />
                    </label>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <label>
                      <Field
                        component={Switch}
                        name='notificationsEnabledForStore'
                        type='checkbox'
                      />
                      <SwitchLabel
                        status={formik.values.notificationsEnabledForStore}
                        value='Store purchase notifications'
                      />
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          )}
          {currentUser.role === 'teacher' && (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Prime <PrimeBadge permanent={true} title={primeMessage} />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>
                    <Field
                      component={Switch}
                      name='primeEnabled'
                      type='checkbox'
                    />
                    <SwitchLabel
                      status={formik.values.primeEnabled}
                      value='Prime'
                    />
                  </label>
                </Grid>
                {formik.values.primeEnabled && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={TextField}
                        label='Prime Purchase Amount'
                        name='primePurchaseAmount'
                        type='number'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label>
                        <Field
                          component={Switch}
                          name='primeAnonymousSongsEnabled'
                          type='checkbox'
                        />
                        <SwitchLabel
                          status={formik.values.primeAnonymousSongsEnabled}
                          value='Anonymous Songs for Prime only'
                        />
                      </label>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label>
                        <Field
                          component={Switch}
                          name='primeDarkModeEnabled'
                          type='checkbox'
                        />
                        <SwitchLabel
                          status={formik.values.primeDarkModeEnabled}
                          value='Dark Mode for Prime only'
                        />
                      </label>
                    </Grid>
                    {formik.values.enableBetaFeatures && (
                      <Grid item xs={12} md={6}>
                        <label>
                          <Field
                            component={Switch}
                            name='primeJukeboxEnabled'
                            type='checkbox'
                          />
                          <SwitchLabel
                            status={formik.values.primeJukeboxEnabled}
                            value='Jukebox for Prime only'
                          />
                        </label>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <label>
                        <Field
                          component={Switch}
                          name='primeSameDayDeliveryEnabled'
                          type='checkbox'
                        />
                        <SwitchLabel
                          status={formik.values.primeSameDayDeliveryEnabled}
                          value='Same-day delivery'
                        />
                      </label>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <label>
                        <Field
                          component={Switch}
                          name='primeSongRemovalEnabled'
                          type='checkbox'
                        />
                        <SwitchLabel
                          status={formik.values.primeSongRemovalEnabled}
                          value='Song removal for Prime only'
                        />
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.info}
                        display='block'
                        variant='body2'
                        gutterBottom
                      >
                        Values entered below will be treated as percentages. To
                        disable any of these just set the value to zero.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={TextField}
                        label='Hallpass Discount'
                        name='primeHallpassDiscount'
                        type='number'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={TextField}
                        label='Song Request Discount'
                        name='primeSongRequestPurchaseDiscount'
                        type='number'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={TextField}
                        label='Store Discount'
                        name='primeStorePurchaseDiscount'
                        type='number'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          )}
          {currentUser.role === 'teacher' && (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Products and Online Purchases
                  </Typography>
                </Grid>
                <Grid item xs={12} md={formik.values.storeEnabled ? 6 : 12}>
                  <label>
                    <Field
                      component={Switch}
                      name='storeEnabled'
                      type='checkbox'
                    />
                    <SwitchLabel
                      status={formik.values.storeEnabled}
                      value='Online purchases'
                    />
                  </label>
                </Grid>
                {formik.values.storeEnabled && (
                  <Grid item xs={12} md={3}>
                    <Field
                      component={TextField}
                      label='Tax Rate'
                      name='taxRate'
                      type='number'
                      variant='outlined'
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <label>
                    <ControlledSwitch
                      controlledBy={'storeEnabled'}
                      name='productsEnabled'
                    />
                    <SwitchLabel
                      status={formik.values.productsEnabled}
                      value='Products page'
                    />
                    {formik.values.storeEnabled && (
                      <em> (required for online purchases)</em>
                    )}
                  </label>
                </Grid>
                {formik.values.storeEnabled && (
                  <Grid item xs={12} md={6}>
                    <label>
                      <Field
                        component={Switch}
                        name='allowPurchasesWithInsufficientFunds'
                        type='checkbox'
                      />
                      <SwitchLabel
                        status={
                          formik.values.allowPurchasesWithInsufficientFunds
                        }
                        value='Purchases with insufficient funds'
                      />
                    </label>
                  </Grid>
                )}
              </Grid>
            </Paper>
          )}
          {currentUser.role === 'teacher' && (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Score Conversions
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Field
                    component={TextField}
                    label='100% and up'
                    name='score100'
                    type='number'
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Field
                    component={TextField}
                    label='90% and up'
                    name='score90'
                    type='number'
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Field
                    component={TextField}
                    label='80% and up'
                    name='score80'
                    type='number'
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Field
                    component={TextField}
                    label='70% and up'
                    name='score70'
                    type='number'
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Field
                    component={TextField}
                    label='60% and up'
                    name='score60'
                    type='number'
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Field
                    component={TextField}
                    label='50% and up'
                    name='score50'
                    type='number'
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
          {currentUser.role === 'student' &&
            settings?.songs?.allowAnonymous && (
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h5' gutterBottom>
                      Song Requests
                    </Typography>
                    <Grid item xs={12}>
                      <label style={{ alignItems: 'center', display: 'flex' }}>
                        <Field
                          component={Switch}
                          disabled={
                            settings.prime.enabled &&
                            settings.prime.anonymousSongsEnabled &&
                            !currentUser.hasPrime
                          }
                          name='anonymousSongs'
                          type='checkbox'
                        />
                        <SwitchLabel
                          status={formik.values.anonymousSongs}
                          value='Anonymous song requests'
                        />
                        {settings.prime.enabled &&
                          settings.prime.anonymousSongsEnabled &&
                          !currentUser.hasPrime && (
                            <span className={classes.lock}>
                              <Lock /> (available with Chem Cash Prime)
                            </span>
                          )}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            )}
          {currentUser.role === 'teacher' && (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Song Requests
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <label>
                    <Field
                      component={Switch}
                      name='songRequestEnabled'
                      type='checkbox'
                    />
                    <SwitchLabel
                      status={formik.values.songRequestEnabled}
                      value='Song requests'
                    />
                  </label>
                </Grid>
                {formik.values.songRequestEnabled && (
                  <>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={TextField}
                        label='Song Request Purchase Amount'
                        name='songRequestPurchaseAmount'
                        type='number'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label>
                        <Field
                          component={Switch}
                          name='preventDuplicateSong'
                          type='checkbox'
                        />
                        <SwitchLabel
                          status={formik.values.preventDuplicateSong}
                          value='Prevent duplicate songs'
                        />
                      </label>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label>
                        <ControlledSwitch
                          controlledBy={'primeAnonymousSongsEnabled'}
                          name='songRequestAnonymousEnabled'
                          prime={formik.values.primeEnabled}
                        />
                        <SwitchLabel
                          status={formik.values.songRequestAnonymousEnabled}
                          value='Anonymous song requests'
                        />
                        {formik.values.primeEnabled &&
                          formik.values.primeAnonymousSongsEnabled && (
                            <em> (required for Prime)</em>
                          )}
                      </label>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Typography variant='h6' gutterBottom>
                    Song Removal Requests
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <label>
                    <ControlledSwitch
                      controlledBy={'primeSongRemovalEnabled'}
                      name='songRemovalRequestEnabled'
                      prime={formik.values.primeEnabled}
                    />
                    <SwitchLabel
                      status={formik.values.songRemovalRequestEnabled}
                      value='Song removal requests'
                    />
                    {formik.values.primeEnabled &&
                      formik.values.primeSongRemovalEnabled && (
                        <em> (required for Prime)</em>
                      )}
                  </label>
                </Grid>
                {formik.values.songRemovalRequestEnabled && (
                  <>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={TextField}
                        label='Song Removal Purchase Amount'
                        name='songRemovalPurchaseAmount'
                        type='number'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Field
                        component={TextField}
                        label='Song Removal Refund Amount'
                        name='songRemovalRefundAmount'
                        type='number'
                        variant='outlined'
                        fullWidth
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          )}
          {currentUser.role === 'teacher' && (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5' gutterBottom>
                    Student Accounts
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    label='Default Student Password'
                    name='defaultStudentPassword'
                    type='text'
                    variant='outlined'
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
