import React from 'react'
import { connect } from 'react-redux'
import { Tooltip, makeStyles } from '@material-ui/core'
import { VisibilityOff } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  gutterLeft: {
    marginLeft: theme.spacing(1),
  },
  gutterRight: {
    marginRight: theme.spacing(1),
  },
}))

const mapStateToProps = (state) => ({
  settings: state.common.settings,
})

const AnonymousBadge = ({ alwaysShow, gutterLeft, gutterRight, settings }) => {
  const classes = useStyles()

  const showBadge = alwaysShow || settings.songs.allowAnonymous

  const iconClass = [
    gutterLeft && classes.gutterLeft,
    gutterRight && classes.gutterRight,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <>
      {showBadge && (
        <Tooltip
          aria-label='Anonymous songs enabled'
          title='Anonymous songs enabled'
          arrow
        >
          <VisibilityOff className={iconClass} color='secondary' />
        </Tooltip>
      )}
    </>
  )
}

export default connect(mapStateToProps, () => ({}))(AnonymousBadge)
