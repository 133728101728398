import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import agent from '../agent'

import { WHATS_NEW_DIALOG_UNLOADED } from '../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  date: {
    color: theme.palette.secondary.main,
  },
  title: {
    color: theme.palette.primary.main,
  },
}))

const mapStateToProps = (state) => ({
  ...state.common,
})

const mapDispatchToProps = (dispatch) => ({
  onExit: () =>
    dispatch({
      type: WHATS_NEW_DIALOG_UNLOADED,
      payload: agent.Auth.save({ viewedChanges: true }),
    }),
})

const WhatsNewDialog = ({ currentUser, onExit }) => {
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (currentUser) setOpen(!currentUser.viewedChanges)
  }, [currentUser])

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      onClick={(ev) => ev.stopPropagation()}
      onExit={onExit}
      onFocus={(ev) => ev.stopPropagation()}
    >
      <DialogTitle>What's New in Chem Cash!</DialogTitle>
      <DialogContent>
        {currentUser?.role === 'student'
          ? StudentWhatsNewContent()
          : TeacherWhatsNewContent()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='contained'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const NewDate = ({ date }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12}>
      <Typography variant='body1' className={classes.date}>
        <em>{date}</em>
      </Typography>
    </Grid>
  )
}

const NewItem = ({ children, title }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12}>
      <Typography variant='subtitle2' className={classes.title}>
        {title}
      </Typography>
      <Typography variant='body2'>{children}</Typography>
    </Grid>
  )
}

const StudentWhatsNewContent = () => (
  <Grid container spacing={4}>
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>Latest Updates</Typography>
      </Grid>
      <NewDate date='January 6, 2025' />
      <NewItem title='Anonymous Song Requests'>
        You now have the option to keep your song requests anonymous to others
        in the class. To enable this feature, go to the new{' '}
        <em>Song Requests</em> section on the <em>Settings</em> page.
        Availability of this feature depends on your teacher's settings and my
        also require a Prime membership. When enabled, your name will appear as
        'anonymous' on the <em>Songs</em> page. Note that your requests to
        remove songs will NOT be anonymous.
      </NewItem>
    </Grid>
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>Previous Updates</Typography>
      </Grid>
      <NewDate date='October 21, 2024' />
      <NewItem title='Pay to Skip Songs'>
        Jukebox now gives you the ability to pay to skip the currently playing
        song on the Spotify queue. To skip a song, go to the <em>Songs</em>{' '}
        page, click on <em>Jukebox</em>, then click on <em>View Queue</em>{' '}
        (visible only if there is music playing). If enabled by your teacher, a
        button will appear under <em>Now Playing</em> to skip that song.
      </NewItem>
      <NewDate date='September 1, 2024' />
      <NewItem title='See Total Fines'>
        You can now see a breakdown of the total amount of fines you have
        incurred on the <em>Account</em> page.
      </NewItem>
      <NewDate date='April 28, 2024' />
      <NewItem title='Search for Products'>
        A new search bar has been added to the <em>Products</em> page that will
        allow you to filter the list of products based on search terms related
        to the product's title.
      </NewItem>
      <NewDate date='September 4, 2023' />
      <NewItem title='Hallpass Usage'>
        You can now view your hallpass usage and see how many passes you have
        left from the new <em>Passes</em> tab found along the bottom navigation.
        This is only available if your teacher has enabled hallpasses in Chem
        Cash.
      </NewItem>
      <NewDate date='August 1, 2023' />
      <NewItem title='Chem Cash Prime'>
        If enabled by your teacher, Chem Cash Prime may give you access to Chem
        Cash features and discounts for a one-time membership fee depending on
        your teacher's settings. To see if your teacher has enabled Chem Cash
        Prime, go to the <em>Store</em> tab and look for the Chem Cash Prime
        banner at the top of the page.
      </NewItem>
      <NewDate date='October 14, 2022' />
      <NewItem title='Dark Mode'>
        Like things dark? Chem Cash now has a dark mode that you can activate by
        going to the Settings tab and switching on dark mode under Appearance.
        Be sure to save your changes in order for the dark mode to activate.
      </NewItem>
      <NewDate date='October 10, 2022' />
      <NewItem title='Hallpasses'>
        If enabled by your teacher, you can now create a digital hallpass for
        using the restroom. Your teacher can choose to set a point value for
        hallpasses, which will be deducted from your account. You can only
        generate one hallpass per day and only if there is not already an active
        hallpass for another student. You can create a hallpass by clicking the
        restroom icon on the Account page.
      </NewItem>
      <NewItem title='Sorting by Dates'>
        Sorting by dates in all tables (transactions, songs, and purchases) now
        works as expected, sorting by date and not alphabetically.
      </NewItem>
      <NewDate date='August 22, 2022' />
      <NewItem title='Score and Transaction Notifications'>
        You will now receive notifications when new or updated assessment scores
        post to your account and when new bonuses and quick purchases post to
        your account.
      </NewItem>
      <NewDate date='August 1, 2022' />
      <NewItem title='Notification Center'>
        You will now see notifications when you first login if there are updates
        to your song and online purchase requests. You will see if your requests
        were approved or denied and will see any notes associted with your
        request.
      </NewItem>
      <NewItem title='Online Purchases'>
        If your teacher has enabled the feature, you can now make purchases
        online! You can add items to your cart on the Store page, manage your
        cart, submit your order, and view your previous orders.
      </NewItem>
      <NewItem title='Product Quantities'>
        You can now see when there are fewer than five items of any given
        product with a new badge shown on the Products or Store page.
      </NewItem>
      <NewDate date='Dec 30, 2021' />
      <NewItem title='Delete Songs'>
        You can now request songs to be removed from your class playlist by
        going to the 'Songs' tab and clicking the trash can icon next to the
        song you want removed. This feature is only available if your teacher
        has activated it.
      </NewItem>
      <NewItem title="What's New Notifications">
        This notification is a new feature! When new Chem Cash udpates are
        released this notification will appear once on the first time you access
        the website after the update is released.
      </NewItem>
    </Grid>
  </Grid>
)

const TeacherWhatsNewContent = () => (
  <Grid container spacing={4}>
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>Latest Updates</Typography>
      </Grid>
      <NewDate date='January 6, 2025' />
      <NewItem title='Anonymous Song Requests'>
        New settings have been added to allow student's song requests to be
        anonymous to others in the class. On the <em>Settings</em> page, new
        settings have been added to the <em>Song Requests</em> and{' '}
        <em>Prime</em> sections. You can enable or disable anonymous song
        requests and even allow anonymous song requests for Prime members only.
        Once these settings are enabled, students have the choice to enable or
        disable anonymous songs on their own <em>Settings</em> page. Note that
        songs will not appear as anonymous to the teacher only to other
        students.
      </NewItem>
    </Grid>
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>Previous Updates</Typography>
      </Grid>
      <NewDate date='October 21, 2024' />
      <NewItem title='Skip Songs with Jukebox'>
        New settings have been added to Jukebox that allow students to pay a fee
        to skip a song. To enable, go to <em>Settings</em> and under{' '}
        <em>Jukebox</em> you can enable or disable skipping songs. When enabled,
        you can set a price for skipping songs.
      </NewItem>
      <NewDate date='October 12, 2024' />
      <NewItem title='Edit Scores'>
        You can now update individual assignment scores without importing a new
        .csv file. Just go to <em>Courses</em>, <em>Manage assignments</em>, and
        then click on <em>View data</em> for the assignment you want to update.
        Scores can then be edited in the <em>Student Scores</em> table at the
        bottom of the dialog window.
      </NewItem>
      <NewDate date='September 22, 2024' />
      <NewItem title='Sortable Groups'>
        The order of student groups on the <em>Random Group Generator</em>{' '}
        dialog can now be rearranged into a custom order by drag and drop.
      </NewItem>
      <NewDate date='September 14, 2024' />
      <NewItem title='Prevent Duplicate Songs'>
        A new setting has been added to the <em>Songs</em> section of the{' '}
        <em>Settings</em> page to prevent students from submitting duplicate
        song requests. If a student tries to submit a request for a title and
        artist that is already on the playlist for their course, they will see
        an error message and the request will not be submitted.
      </NewItem>
      <NewDate date='September 1, 2024' />
      <NewItem title='Student Fines'>
        You can now add fines to student accounts. To use, enable <em>Fines</em>{' '}
        on the <em>Settings</em> page, which will add new menu options to the{' '}
        <em>Update Accounts</em> button on the <em>Accounts</em> page. Use the{' '}
        <em>Manage Fines</em> feature to add set fines you can lookup quickly
        when adding a fine. Fines can be added to individual student accounts or
        to multiple students at once, just select <em>Add Fines</em> from the{' '}
        <em>Update Accounts</em> menu.
      </NewItem>
      <NewDate date='August 1, 2024' />
      <NewItem title='Jukebox (beta)'>
        With Jukebox, you can connect a Spotify account with Chem Cash so that
        students can pay points to add approved songs from their class playlist
        to the Spotify queue to play next. Jukebox also allows students to see
        the song currently playing on the connected Spotify account and a list
        of upcoming songs. Jukebox can be enabled as a feature of Chem Cash
        Prime or left for all users to access. (As a beta feature, you must have
        beta features enabled in <em>Settings</em> to access Jukebox.)
      </NewItem>
      <NewDate date='April 28, 2024' />
      <NewItem title='Search for Products in Gallery View'>
        A new search bar has been added to the <em>Products</em> page gallery
        view that will allow teachers and students to filter the list of
        products based on search terms related to the product's title.
      </NewItem>
      <NewDate date='January 25, 2024' />
      <NewItem title='Custom Notes For Approval or Rejection of Song Requests'>
        You can now provide a custom message when approving or rejecting song
        requests. Just select the <em>Other</em> option when processing songs to
        reveal the input box for your custom message.
      </NewItem>
      <NewDate date='November 25, 2023' />
      <NewItem title='Email Notifications'>
        You can now choose to receive email notifications whenever a song
        request or a store purchase is submitted. Options are available in the{' '}
        <b>Notifications</b> section of the <em>Settings</em> page to
        individually control these notifications and to provide an email address
        where the notifications will be delivered.
      </NewItem>
      <NewDate date='October 28, 2023' />
      <NewItem title='Assignment Data Viewer'>
        You can now view statistical data for individual assignments including
        average, pass/fail rates, grade breakdowns, a histogram of student
        scores, and a list of student scores. Just go to the <em>Courses</em>{' '}
        page, then click the <em>Manage assignments</em> button for a course.
        From the dialog that opens, click on the <em>View data</em> button for
        any assignment.
      </NewItem>
      <NewDate date='October 8, 2023' />
      <NewItem title='Random Group Generator'>
        You can now create 2 to 20 random groups of students in a given course
        using the Random Group Generator found on the Courses page.
      </NewItem>
      <NewDate date='September 4, 2023' />
      <NewItem title='Student Pass Usage'>
        Students can view their hallpass usage and see how many passes they have
        left from a new <em>Passes</em> tab found along the bottom naviagation
        bar for student accounts only.
      </NewItem>
      <NewDate date='August 1, 2023' />
      <NewItem title='Chem Cash Prime'>
        When enabled, Chem Cash Prime allows you to charge students a one-time
        membership fee for access to various features and discounts of your
        choice including access to dark mode, the ability to remove songs,
        same-day delivery, and discounts on songs, purchases, and hallpasses. Go
        to the <em>Settings</em> page to enable Prime as this is a great way for
        students to spend their points. To see the Prime banner and purchase
        experience that students will have, go to the bottom of the{' '}
        <em>Products</em> page when Prime is enabled.
      </NewItem>
      <NewItem title='Suspend Student Accounts'>
        A new toggle on the 'Edit Student' dialog allows students to be dropped
        which suspends their account. Students will not be able to create
        hallpasses, make song requests, or submit online purchases while thier
        account is dropped/suspended. Dropped/suspended students will not appear
        in the bulk bonus or bulk purchase table and they will not affect the
        data in the economy view.
      </NewItem>
      <NewItem title='New Hallpass Limitations'>
        While students can only generate one hallpass per day, you can now limit
        the total number of hallpasses a student can generate. You can also now
        prevent students from purchasing a hallpass if they do not have enough
        points. You can enable these new limitations in the Hallpasses section
        of the <em>Settings</em> page. Once students reach this limit, they will
        not be able to generate their own hallpasses (if this feature is
        enabled) but you will still be able to generate a pass for them if
        needed, which is also true for students with insufficient points for a
        hallpass.
      </NewItem>
      <NewItem title='Table View for Managing Products'>
        A new table view is the default for managing products. The table quickly
        shows you each product's name, cost, max purchase quantity, and on-hand
        quantity each of which you can sort by. You can still quickly update
        inventory, edit products, and delete products from this new table view.
        You can also search for products. Of course, the original grid view,
        similar to what students see, is still available via a toggle switch at
        the top of the Manage Products page.
      </NewItem>
      <NewItem title='Product Inventory Updates When Purchases are Submitted'>
        Product inventory in the Store is updated once a purchase is submitted
        rather than approved. This ensures that when a purchase is submitted for
        an item that would then be out of stock, no other student can also
        submit an order for it.
      </NewItem>
      <NewItem title='New Sorting When Processing Online Orders and Song Requests'>
        When processing online purchases and song requests, you will see that
        they are now sorted oldest to newest rather than the other way around.
        This makes it more first-come-first served.
      </NewItem>
      <NewItem title='Hallpasses Out of Beta'>
        Hallpasses are now ready for primetime and are out of beta!
      </NewItem>
      <NewDate date='October 14, 2022' />
      <NewItem title='Dark Mode'>
        Chem Cash now has a dark mode that you or students can activate by going
        to the Settings tab and switching on dark mode under Appearance. Be sure
        to save your changes in order for the dark mode to activate.
      </NewItem>
      <NewDate date='October 10, 2022' />
      <NewItem title='Hallpasses (beta)'>
        If you have beta features enabled, you can now enalbe hallpasses. This
        allows teachers and students to create digital hallpasses for students
        to leave the classroom. You can choose to set a point value for
        hallpasses, which will automatically be deducted from student's
        accounts. Students can only generate one hallpass per day and only if
        there is not already an active hallpass for another student. You can
        create a hallpass for a student anytime by clicking the restroom icon on
        the Account page when you have a student account open. To view, edit,
        and delete active and expired hallpasses, go to the Accounts page and
        click the Update Accounts button. Then select Manage Hallpasses.
      </NewItem>
      <NewItem title='Sorting by Dates'>
        Sorting by dates in all tables (transactions, songs, and purchases) now
        works as expected, sorting by date and not alphabetically.
      </NewItem>
      <NewDate date='August 28, 2022' />
      <NewItem title='Create and Manage Pre-set Bonuses'>
        You can now create and manage pre-set bonuses for quickly adding bonus
        points to a student's account. In the 'Update Accounts' menu on the
        'Accounts' page, you can now select 'Manage Bonuses' to create, edit,
        and delete pre-set bonuses that can be added to student's accounts. When
        adding a bonus to a single student or to many students in a class
        period, you will now see a dropdown menu of any pre-set bonuses you have
        created that will auto-populate the amount field as well. Of course,
        free-form bonuses can still be added.
      </NewItem>
      <NewItem title='Bonus and Product Selectors Available for Bulk Purchases'>
        When adding a bulk bonus or bulk quick purchase to multiple students in
        a class period, a dropdown of bonuses or products is now available.
        Selecting a bonus or product from this dropdown will auto-populate the
        amount field with the amount of the bonus or the cost of the item
        including tax.
      </NewItem>
      <NewItem title='Support for Evergreen School District'>
        Bock can now import students from the Evergreen School District into the
        app when creating courses :) Support for importing scores is coming
        soon!
      </NewItem>
      <NewDate date='August 22, 2022' />
      <NewItem title='Score and Transaction Notifications for Students'>
        Students will now receive notifications when new or updated assessment
        scores post to their account and when new bonuses and quick purchases
        post to their account.
      </NewItem>
      <NewDate date='August 1, 2022' />
      <NewItem title='Online Purchases'>
        Students can now submit online orders for products! New settings in the{' '}
        <em>Products and Online Purchases</em> section allow you to control
        whether students can submit online purchases or not, whether students
        can submit online purchases if they do not have sufficient points, and a
        tax rate to be applied to all online purchases of products (does not
        apply to song requests or removal requests; can be set to zero if you do
        not want to charge a tax). You can also add purchases to a student's
        account from the <em>Accounts</em> tab.
      </NewItem>
      <NewItem title='Processing Purchases'>
        If you have enabled online purchases, a new <em>Process Purchases</em>{' '}
        dialog is available to approve or reject online purchases submitted by
        students in a manner similar to the song request processing feature.
        When processing purchases, you will have the option to update the order
        quantity of each item, add items to the order, remove items from the
        order, add a note to the order, and approve or reject the order. Upon
        approval of the order, the on-hand quantity of each product will be
        automaticaly adjusted.
      </NewItem>
      <NewItem title='Notification Center for Student Accounts'>
        Students will now see notifications when they first login if there are
        any updates to their song and online store purchase requests. Approval
        or rejection notes will also be included with the notification.
      </NewItem>
      <NewItem title='Inventory Control Buttons and Quantity Display for Products'>
        On the <em>Products</em> page, two new inventory control buttons are now
        available to quickly increase or decrease the amount of on-hand product.
        The quantity of each product is also now visible directly on each
        product image. Students will only see the on-hand quantity when there
        are fewer then five items remaining in stock.
      </NewItem>
      <NewItem title='Max Purchase Quantity for Products'>
        A new field is now available for products that sets the maximum purchase
        quantity limit for each product. When combined with the online store,
        this will limit the quantity of items a student can add to their cart
        for each product.
      </NewItem>
      <NewItem title='Add Quick Purchases with Automatic Inventory Mangement'>
        Quick purchases allow you to add a deduction to a student's account for
        the purchase of a single product from the store or you can enter a
        custom description. When products from the store are purchased, the
        inventory is automatically reduced by one.
      </NewItem>
      <NewItem title='Points Multiplier for Assignments'>
        Assignments can now be weighted with a multiplier to increase or
        decrease their point value. By default, all assignments are imported
        with a multiplier of 1. To change the multiplier for an assignment in a
        course go to <em>Courses</em> and click the <em>Manage Assignments</em>{' '}
        button for that course. Then click the Edit button for the assignment
        whose multiplier you want to change. The multiplier applies to the
        points and not to the score.
      </NewItem>
      <NewItem title='Google Drive Hosted Images Now Easier to Use'>
        You can now directly use the link generated by the <em>Get link</em>{' '}
        feature on Google Drive to add custom product images to your store.
        Images hosted on Google Drive must have their share permissions set to{' '}
        <em>Anyone with the link</em> to properly display for all users.
      </NewItem>
      <NewItem title='Song Requests Badge'>
        A badge now appears on the <em>Process Songs</em> button to indicate the
        number of song requests that are pending approval. A similar badge also
        appears on the <em>Process Purchases</em> button.
      </NewItem>
      <NewItem title='Improved Settings'>
        The settings page has been improved to make clear which features are
        enabled and disabled. A new school district setting has been added
        because <em>somebody</em> had to go and leave CCSD :) so this will
        ensure that there are no username conflicts with student accounts.
      </NewItem>
      <NewDate date='Jan 31, 2022' />
      <NewItem title='In Stock and Out of Stock Products'>
        Products with a quantity of zero will no longer be visible to students.
        Teachers will see these products in an "Out of Stock" section on the
        Products page. Once the product's quantity is greater than zero, the
        product will appear in the "In Stock" section for teachers and will be
        visible to students.
      </NewItem>
      <NewDate date='Jan 23, 2022' />
      <NewItem title='Student Account Balances Visible When Processing Song Requests'>
        Student account balances are now visible in the song request processing
        dialog so you can quickly identify with a red/green color indicator if a
        student has enough points to buy a song request or a song removal
        request.
      </NewItem>
      <NewItem title='Song Totals Visible When Processing Song Requests'>
        The total number of song requests to process is now visible in the song
        request processing dialog along with a running count of requests
        remaining to process.
      </NewItem>
      <NewDate date='Jan 17, 2022' />
      <NewItem title='Economy View'>
        You can now view a summary of details about the account balances for
        students in your courses including the total number of points available
        in your economy and an interactive table containing the account balances
        of all students in all of your courses.
      </NewItem>
      <NewItem title='Beta Features Flag'>
        A new flag that enables beta features when they are available can now be
        found in the Account Details section of the Settings page.
      </NewItem>
      <NewDate date='Dec 30, 2021' />
      <NewItem title='Delete Songs'>
        Students can now request songs to be removed from thier class playlist
        by going to the 'Songs' tab and clicking the trash can icon, once you
        have activated the feature in the Settings.
      </NewItem>
      <NewItem title='Delete Songs Settings'>
        New settings have been added to control student ability to request songs
        for deletion as well as settings for the amount of points charged for a
        song removal and the amount of points refunded to the person whose song
        was removed.
      </NewItem>
      <NewItem title='Search for Products'>
        You can now search for products in a dropdown menu when entering
        purchases on the accounts page and the cost of the product will
        automatically populate in the amount field.
      </NewItem>
      <NewItem title="What's New Notifications">
        This notification is a new feature! When new Chem Cash udpates are
        released this notification will appear once on the first time you access
        the website after the update is released.
      </NewItem>
    </Grid>
  </Grid>
)

export default connect(mapStateToProps, mapDispatchToProps)(WhatsNewDialog)
