import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Checkbox,
  Grid,
  FormControlLabel,
  Paper,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import { InfoOutlined } from '@material-ui/icons'

import JukeboxDialog from '../Jukebox/JukeboxDialog'
import PageHeader from '../PageHeader'
import SongRequestDialog from './SongRequestDialog'
import agent from '../../agent'

import {
  SONGS_PAGE_UNLOADED,
  STUDENT_SONGS_PAGE_LOADED,
  SUBMIT_SONG_REMOVAL_REQUEST,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  paper: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  status: {
    alignItems: 'center',
    display: 'flex',
  },
}))

const mapStateToProps = (state) => ({
  ...state.songs,
  currentUser: state.common.currentUser,
  settings: state.common.settings,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (song) =>
    dispatch({
      type: SUBMIT_SONG_REMOVAL_REQUEST,
      payload: agent.Songs.update(song),
      snackbar: {
        message: 'Song removal request submitted',
        variant: 'success',
      },
    }),
  onLoad: () =>
    dispatch({
      type: STUDENT_SONGS_PAGE_LOADED,
      payload: agent.Songs.forStudent(),
    }),
  onUnload: () => dispatch({ type: SONGS_PAGE_UNLOADED }),
})

const StudentSongs = ({
  course,
  courseSongs,
  currentUser,
  onDelete,
  onLoad,
  onUnload,
  settings,
  userSongs,
}) => {
  const [mySongsOnly, setMySongsOnly] = useState(true)
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])

  const classes = useStyles()

  const allowDelete =
    (settings.songRemovalRequestEnabled &&
      (!settings.prime.enabled || !settings.prime.songRemovalEnabled)) ||
    (settings.prime.enabled &&
      settings.prime.songRemovalEnabled &&
      currentUser.hasPrime)

  const handleMySongsOnlyClick = (event) => {
    setMySongsOnly(event.target.checked)
  }

  const getColumns = () => [
    {
      title: 'Request Date',
      field: 'createdAt',
      editable: 'never',
      render: (rowData, renderType) =>
        renderType === 'row'
          ? rowData.createdAt.format('ddd, MMM D, YYYY')
          : rowData.format('ddd, MMM D, YYYY'),
    },
    { title: 'Song Title', field: 'title' },
    { title: 'Song Artist', field: 'artist' },
    ...(!mySongsOnly
      ? [
          {
            title: 'Purchased by',
            field: 'purchasedBy',
            editable: 'never',
          },
        ]
      : []),
    {
      title: 'Status',
      field: 'status',
      lookup: {
        approved: 'approved',
        pendingApproval: 'pending approval',
        pendingRemoval: 'pending removal',
        rejected: 'rejected',
        removed: 'removed',
      },
      render: (rowData, renderType) =>
        renderType === 'row' ? (
          <span style={{ alignItems: 'center', display: 'flex' }}>
            {rowData.statusDescription ? (
              <>
                {rowData.status}
                <Tooltip
                  arrow
                  color='primary'
                  enterTouchDelay={0}
                  title={rowData.statusDescription}
                >
                  <InfoOutlined style={{ marginLeft: '8px' }} />
                </Tooltip>
              </>
            ) : (
              rowData.status
            )}
          </span>
        ) : (
          <span>{rowData}</span>
        ),
    },
  ]

  const getMappedData = (source) =>
    source.map((song) => ({
      createdAt: moment(song.createdAt),
      title: song.title,
      artist: song.artist,
      purchasedBy:
        settings.songs.allowAnonymous && song.student.anonymousSongs
          ? 'anonymous'
          : `${song.student.firstName} ${song.student.lastName}`,
      status: song.status,
      statusDescription: song.statusDescription,
      _id: song._id,
    }))

  useEffect(() => {
    onLoad()

    return () => {
      return onUnload()
    }
  }, [])

  useEffect(() => {
    setColumns(getColumns())
    setData(getMappedData(mySongsOnly ? userSongs : courseSongs))
  }, [courseSongs, mySongsOnly, userSongs])

  return (
    <>
      <PageHeader
        title={
          'Songs for ' +
          (mySongsOnly ? currentUser.firstName : 'Period ' + course.period)
        }
      >
        <JukeboxDialog />
        <SongRequestDialog />
      </PageHeader>

      <Paper className={classes.paper}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs={12} className={classes.grid}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mySongsOnly}
                  onChange={handleMySongsOnlyClick}
                  name='mySongsOnly'
                />
              }
              label='show only my songs'
            />
          </Grid>
        </Grid>
      </Paper>

      <MaterialTable
        title='Class Playlist'
        columns={columns}
        data={data}
        localization={{
          body: {
            deleteTooltip: allowDelete
              ? 'Delete song'
              : 'Delete song (Requires Prime Membership)',
            emptyDataSourceMessage:
              'No songs have been added to this class playlist',
            editRow: {
              deleteText: `Delete this song for ${settings.songRemovalPurchaseAmount} points?`,
              saveTooltip: 'Delete song',
            },
          },
        }}
        options={{ actionsColumnIndex: -1, paging: false, toolbar: false }}
        editable={{
          isDeletable: (rowData) =>
            rowData.status === 'approved' && allowDelete,
          isDeleteHidden: () => !settings.songRemovalRequestEnabled,
          onRowDelete: (song) =>
            new Promise((resolve) => {
              onDelete({
                id: song._id,
                status: 'pending removal',
                statusDescription: '',
              })
              resolve()
            }),
        }}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentSongs)
