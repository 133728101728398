import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleHalfStroke,
  faEyeSlash,
  faMoneyBillTrendUp,
  faMusic,
  faTag,
  faTicket,
  faTruck,
  faMoneyBillWave,
  faVolumeXmark,
} from '@fortawesome/free-solid-svg-icons'
import { faTurntable } from '@fortawesome/pro-solid-svg-icons'
import Confetti from 'react-confetti'

import Logo from '../Logo'

const useStyles = makeStyles((theme) => ({
  logo: {
    'display': 'flex',
    'justifyContent': 'center',
    '& > img': {
      width: '75%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
  },
  section: {
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    color: theme.palette.common.white,
    fontFamily: '"Carter One", serif',
    overflow: 'hidden',
    padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    },
  },
  text: {
    fontFamily: 'inherit',
  },
}))

const PrimeConfirmation = ({ celebrate, enabled, prime }) => {
  const classes = useStyles()

  return (
    <Grid container alignItems='center' className={classes.section} spacing={2}>
      {celebrate && <Confetti numberOfPieces={750} recycle={false} />}
      <Grid item xs={12} md={4} className={classes.logo}>
        <Logo className={classes.logo} prime={true} />
      </Grid>
      {enabled.any && (
        <Grid item xs={12} md={8}>
          <Typography
            className={classes.text}
            color='inherit'
            variant='h2'
            gutterBottom
          >
            Welcome to Prime!
          </Typography>
          <Typography variant='h5'>
            You now have instant access to...
          </Typography>
          <Typography color='inherit' variant='h6'>
            <ul className='fa-ul'>
              {enabled.darkMode && (
                <li>
                  <FontAwesomeIcon icon={faCircleHalfStroke} listItem />
                  both light and dark modes in Settings
                </li>
              )}
              {enabled.discounts.songs && (
                <li>
                  <FontAwesomeIcon icon={faMusic} listItem />a{' '}
                  {prime.songRequestPurchaseDiscount}% discount on all song
                  requests
                </li>
              )}
              {enabled.songRemoval && (
                <li>
                  <FontAwesomeIcon icon={faVolumeXmark} listItem />
                  ability to remove any songs from the class playlist for a fee
                </li>
              )}
              {enabled.anonymousSongs && (
                <li>
                  <FontAwesomeIcon icon={faEyeSlash} listItem />
                  keep your song requests anonymous from others in the class
                </li>
              )}
              {enabled.songRemoval && (
                <li>
                  <FontAwesomeIcon icon={faMoneyBillTrendUp} listItem />
                  earn bonus points when one of your songs is removed
                </li>
              )}
              {enabled.jukebox && (
                <li>
                  <FontAwesomeIcon icon={faTurntable} listItem />
                  access to Jukebox to preview songs on the class playlist, plus
                  add songs to the queue to play next{' '}
                  {enabled.skipSongs && ' or skip songs '} for a fee
                </li>
              )}
              {enabled.discounts.store && (
                <li>
                  <FontAwesomeIcon icon={faTag} listItem />a{' '}
                  {prime.storePurchaseDiscount}% discount on all online store
                  purchases
                </li>
              )}
              {enabled.sameDay && (
                <li>
                  <FontAwesomeIcon icon={faTruck} listItem />
                  same-day pickup for online purchases rather than at the end of
                  the week
                </li>
              )}
              {enabled.discounts.hallpass && (
                <li>
                  <FontAwesomeIcon icon={faTicket} listItem />a{' '}
                  {prime.hallpassDiscount}% discount on all hallpass purchases
                </li>
              )}
              <li>
                <FontAwesomeIcon icon={faMoneyBillWave} listItem />
                your membership is good for the whole school year
              </li>
            </ul>
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default PrimeConfirmation
