import React from 'react'
import { connect } from 'react-redux'
import { Tooltip, makeStyles } from '@material-ui/core'
import { MusicNote, MusicOff } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  gutterLeft: {
    marginLeft: theme.spacing(1),
  },
  gutterRight: {
    marginRight: theme.spacing(1),
  },
}))

const mapStateToProps = (state) => ({
  settings: state.common.settings,
})

const JukeboxBadge = ({
  alwaysShow,
  enabled,
  gutterLeft,
  gutterRight,
  settings,
}) => {
  const classes = useStyles()

  const disabledMessage =
    'Jukebox disabled. Go to Settings > Jukebox > Manage Playlists to create a playlist for this course.'

  const showBadge =
    alwaysShow || (settings.jukebox.enabled && settings.enableBetaFeatures)

  const iconClass = [
    gutterLeft && classes.gutterLeft,
    gutterRight && classes.gutterRight,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <>
      {showBadge && (
        <Tooltip
          aria-label={enabled ? 'Jukebox enabled' : disabledMessage}
          title={enabled ? 'Jukebox enabled' : disabledMessage}
          arrow
        >
          {enabled ? (
            <MusicNote className={iconClass} color='primary' />
          ) : (
            <MusicOff className={iconClass} color='secondary' />
          )}
        </Tooltip>
      )}
    </>
  )
}

export default connect(mapStateToProps, () => ({}))(JukeboxBadge)
