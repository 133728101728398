import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Container,
  Dialog,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import {
  AccountBalance,
  Assignment,
  Gavel,
  Group,
  Money,
  MusicNote,
  Person,
  ShoppingCart,
  SkipNext,
  Wc,
} from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlask } from '@fortawesome/free-solid-svg-icons'
import { faTurntable } from '@fortawesome/pro-solid-svg-icons'

import DialogAppBar from '../DialogAppBar'
import EconomySummaryTable from './EconomySummaryTable'
import ListErrors from '../ListErrors'
import PageHeader from '../PageHeader'
import PageWrapper from '../PageWrapper'
import Spinner from '../Spinner'
import StatCard from '../StatCard'
import { SlideUpTransition } from '../../transitions'

import agent from '../../agent'

import {
  ECONOMY_DIALOG_LOADED,
  ECONOMY_DIALOG_UNLOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.economy,
  settings: state.common.settings,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: ECONOMY_DIALOG_LOADED,
      payload: agent.Scores.getAll(),
    }),
  onUnload: () =>
    dispatch({
      type: ECONOMY_DIALOG_UNLOADED,
    }),
})

// @todo : add stats for Dark Mode, Anonymous Songs, and Removed Songs
const Dashboard = ({ data, settings }) => (
  <Grid container spacing={3}>
    <StatCard
      icon={<Assignment fontSize='large' />}
      title='Assessments'
      value={data.reduce(
        (prev, cur) => prev + (cur.totals.assessments || 0),
        0
      )}
      round
    />
    <StatCard
      icon={<Money fontSize='large' />}
      title='Bonuses'
      value={data.reduce((prev, cur) => prev + (cur.totals.bonuses || 0), 0)}
      round
    />
    {settings.fines.enabled && (
      <StatCard
        icon={<Gavel fontSize='large' />}
        title='Fines'
        value={data.reduce((prev, cur) => prev + (cur.totals.fines || 0), 0)}
        round
      />
    )}
    {settings.hallpass.enabled && (
      <StatCard
        icon={<Wc fontSize='large' />}
        title='Hallpasses'
        value={data.reduce((prev, cur) => prev + cur.totals.passes, 0)}
        round
      />
    )}
    {settings.jukebox.enabled && settings.enableBetaFeatures && (
      <StatCard
        icon={<FontAwesomeIcon icon={faTurntable} size='lg' />}
        title='Jukebox Requests'
        value={data.reduce((prev, cur) => prev + cur.totals.jukebox, 0)}
        round
      />
    )}
    <StatCard
      icon={<AccountBalance fontSize='large' />}
      title='Points'
      value={data.reduce((prev, cur) => prev + (cur.totals.account || 0), 0)}
      round
    />
    {settings.prime.enabled && (
      <StatCard
        icon={<FontAwesomeIcon icon={faFlask} size='lg' />}
        title='Prime Memberships'
        value={data.reduce((prev, cur) => prev + cur.hasPrime, 0)}
        round
      />
    )}
    {settings.storeEnabled && (
      <StatCard
        icon={<ShoppingCart fontSize='large' />}
        title='Purchases'
        value={data.reduce(
          (prev, cur) => prev + (cur.totals.purchases || 0),
          0
        )}
        round
      />
    )}
    {settings.jukebox.enabled &&
      settings.enableBetaFeatures &&
      settings.jukebox.skipSongs.enabled && (
        <StatCard
          icon={<SkipNext fontSize='large' />}
          title='Skip Song Requests'
          value={data.reduce((prev, cur) => prev + cur.totals.skipSongs, 0)}
          round
        />
      )}
    <StatCard
      icon={<Group fontSize='large' />}
      title='Students'
      value={data.length}
      round
    />
    {settings.songRequestEnabled && (
      <StatCard
        icon={<MusicNote fontSize='large' />}
        title='Song Requests'
        value={data.reduce((prev, cur) => prev + cur.totals.songs, 0)}
        round
      />
    )}
    <StatCard icon={<Person fontSize='large' />} title='Top Student' round>
      {data[0]?.firstName} {data[0]?.lastName}
    </StatCard>
    <Grid item xs={12}>
      <Paper elevation={3} style={{ padding: '16px', overflow: 'scroll' }}>
        <Typography gutterBottom variant='h6'>
          Account Summary
        </Typography>
        <EconomySummaryTable accounts={data} settings={settings} />
      </Paper>
    </Grid>
  </Grid>
)

const EconomyDialog = ({
  errors,
  inProgress,
  onLoad,
  onUnload,
  settings,
  students,
}) => {
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
    onLoad()
  }

  const handleClose = () => {
    setOpen(false)
    onUnload()
  }

  useEffect(() => {
    setData(students.sort((a, b) => b.totals.account - a.totals.account))
  }, [students])

  return (
    <>
      <Button onClick={handleClickOpen} size='small'>
        View Economy
      </Button>

      <Dialog
        fullScreen
        open={open}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        TransitionComponent={SlideUpTransition}
      >
        <DialogAppBar onClose={handleClose} title='Accounts' />
        <PageWrapper>
          <Container>
            <PageHeader title='Economy View' />
            <ListErrors errors={errors} />
            {inProgress ? (
              <Spinner message='Computing...' />
            ) : data.length > 0 ? (
              <Dashboard data={data} settings={settings} />
            ) : (
              <Alert severity='info'>
                <AlertTitle>No data available</AlertTitle>
                You must create at least one course, add students to that
                course, and add bonuses, purchases, or scores before any data
                will be visible here
              </Alert>
            )}
          </Container>
        </PageWrapper>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(EconomyDialog)
